import React from 'react';
import {aboutmeService} from "../Services/aboutmeService";
import PageTemplate from '../Components/PageTemplate';

function AboutPage(props: any) {
    return (
        <PageTemplate data={aboutmeService} />
    )
}

export default AboutPage;
