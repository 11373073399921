import React from 'react';

export function TerminalBody(props: any) {

    return (
        <div className='terminal-body'>
            <span style={{fontWeight: 'bolder', fontSize: '40px'}}>{props.context.title}</span>
            <span>{props.context.subtitle}</span>
            <div className='context-content' dangerouslySetInnerHTML={{ __html: props.context.content}} ></div>
        </div>
    )
}
