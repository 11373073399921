import home from "../Assets/icon/icons8-home.svg";
import about from "../Assets/icon/icons8-user-male.svg";
import project from "../Assets/icon/icons8-document.svg";
import experience from "../Assets/icon/icons8-opened-folder.svg";
import skills from '../Assets/icon/icons8-puzzle.svg';

export const menuContext =
[
    {name: 'Home', tag: home, path: '/'},
    {name: 'About Me', tag: about, path: '/about'},
    {name: 'My Projects', tag: project, path: '/projects'},
    {name: 'My Skills', tag: skills, path: '/skills'},
    {name: 'My Experiences', tag: experience, path: '/experience'}
]
