import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { Container } from "react-bootstrap";
import './App.css';

import Home from './router/Homepage'
import About from "./router/Aboutpage";
import Project from "./router/ProjectPage";
import Skill from "./router/SkillPage";
import Experience from './router/ExperiencePage';
import { SocialLink } from './Components/SocialLink';


type MyState = {
    title: string,
    headerLink: { title: string, path: string }[],
    home: { title: string, subtitle: string },
    about: { title: string },
    portfolio: { title: string },
    contact: { title: string },
}

class App extends React.Component<{}, MyState> {

    // eslint-disable-next-line @typescript-eslint/no-useless-constructor
    constructor(props: any) {
        super(props);
    }

    render() {
        return (
            <div className='main'>
                <Router>
                    <Container fluid className="p-0 main-container">
                        <Route path="/" exact render={() => <Home />} />
                        <Route path="/about" exact render={() => <About />} />
                        <Route path="/projects" exact render={() => <Project />} />
                        <Route path="/skills" exact render={() => <Skill />} />
                        <Route path='/experience' exact render={() => <Experience />} />
                    </Container>
                </Router>
                <div className='footer'>
                    <SocialLink size='100' />
                </div>
            </div>

        );
    }

}

export default App;
