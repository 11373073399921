import React from 'react';
import PageTemplate from '../Components/PageTemplate';


function ExperiencePage(props: any) {
    return (
        <PageTemplate />
    )
}

export default ExperiencePage;
