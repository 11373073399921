import React, { useState } from 'react';
import { Menu } from './Menu';
import { menuContext } from "../Services/menuServices";
import '../style/Main.scss';
import { Terminal } from './terminal';
import { TypeWriter } from './TypeWriter';


function PageTemplate(props: any) {
    const words = ['Hello!', 'Welcome to My Personal Computer!', 'You might ask', 'Who Am I?', 'Well ~~~ ', 'I am a Full Stack Developer', 'I am also a Software Engineer', 'I am .....'];
    const [typeWriterDone, setTypeWriterDone] = useState(false);

    return (
        <div className='home-page'>
            <div className='main-page-container'>
                <div className='main-context'>
                    <div className='terminal-container'>
                        {
                            !typeWriterDone ? (
                                props.data ? 
                                (<Terminal title={props.data.title} subtitle={props.data.subtitle} context={props.data.context} />) : (<></>)
                            ) : (
                                <span style={{ color: '#8bfa02', fontSize: '40px' }}><TypeWriter words={words} setTypeComplete={setTypeWriterDone} /></span>
                            )
                        }
                    </div>
                </div>
            </div>
            <div className='side-bar'>
                {
                    menuContext.map(item => (
                        <Menu name={item.name} tag={item.tag} path={item.path} />
                    ))
                }
            </div>
        </div>
    )
}

export default PageTemplate;