import React, { useState } from "react";
import {TerminalHeader} from "./terminalHeader";
import {TerminalBody} from "./terminalBody";
import '../style/Terminal.scss';

export function Terminal(props: any) {
    const [closeTerminal, setCloseTerminal] = useState(false);
    
    return (
        !closeTerminal ? (
            <div className='terminal-style'>
            <TerminalHeader title={props.title} subtitle={props.subtitle} setClose={setCloseTerminal} />
            <TerminalBody context={props.context}/>
        </div>) : (<></>)
    )
}
