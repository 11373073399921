import React from 'react';
import PageTemplate from '../Components/PageTemplate';
import '../style/Home.scss';
import homePageContext from '../Services/homepageService';

function Homepage(props: any) {

    return (
        <PageTemplate data={homePageContext}  />
    )
}

export default Homepage;
