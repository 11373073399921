import React from 'react';
import {Typewriter} from 'react-simple-typewriter'

export function TypeWriter(props: any) {
    return (
    <div style={{background: '#000', paddingLeft: '10px', width: '50%'}}>
        <Typewriter
            words={props.words}
            loop={1}
            cursor
            cursorStyle='_'
            typeSpeed={70}
            deleteSpeed={50}
            delaySpeed={1000}
            onLoopDone={() => setInterval(() => {props.setTypeComplete(true)}, 1000)}
        />
    </div>)
}
