import React from "react";
import linkedin from '../Assets/icon/icons8-linkedin.svg';
import google from '../Assets/icon/icons8-google-plus.svg';
import github from '../Assets/icon/icons8-github.svg';

export function SocialLink(Props: any) {
    const { size } = Props;

    return (
        <div className='d-flex justify-content-center'>
            <a style={{cursor: "pointer"}} href='https://www.linkedin.com/in/zeheng-lin-23114b149/'>
                <img src={linkedin} alt='linkedin' style={{ width: `${size}px`}}/>
            </a>
            <a style={{cursor: "pointer"}} href='mailto: zeheng.lin@outlook.com'>
                <img src={google} alt='google' style={{ width: `${size}px`}}/>
            </a>
            <a style={{cursor: "pointer"}} href='https://github.com/zehengLin-lzh'>
                <img src={github} alt='github' style={{ width: `${size}px`}}/>
            </a>
        </div>
    )
}
