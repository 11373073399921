export const aboutmeService = {
    title: 'ZEHENG LIN',
    subtitle: 'This is a little bit about me',
    context: {
        title: 'A Little Bit of me',
        content: '<p>Hello, my name is Zeheng Lin, feel free to call me Matthew or matt.\n' +
            '                    I\'m a Full stack engineer working on Sumer Inc. with experience on React/React Native,\n' +
            '                    Node.JS(Express, Apollo serve), Postgresql, MySQL, MongoDB, AWS, and data process and modeling skills such as using\n' +
            '                    Python, R, SAS, Machine Learning and Deep Learning (CNN, LSTM/RNN) to predict future result.\n' +
            '                </p>\n' +
            '\n' +
            '                <p>I am an experienced full stack engineer with data mining and machine learning\n' +
            '                    background from University of Toronto Scarborough with proven Full stack development\n' +
            '                    skills as well as AWS and GCP cloud computing and database manipulation.\n' +
            '                    Passionate developer whose always willing to learn from work and teams to create\n' +
            '                    new solution.</p>\n' +
            '\n' +
            '                <p>My recent project is to build a cross platform mobile application for the company\n' +
            '                    which allow client to fetch the closest grocery store and shop online\n' +
            '                    and enjoy the free delivery services. The app was already on both platform and feel free\n' +
            '                    to try it and enjoy the free delivery services.\n' +
            '                    (For IOS devices download <a href="https://apps.apple.com/cn/app/sumer/id1513840235">here</a>,\n' +
            '                    for android devices download <a href="https://play.google.com/store/apps/details?id=com.sumer.sumer">here</a>).\n' +
            '                </p>\n' +
            '\n' +
            '                <p>I love learning new thing relative to technology, and I am constantly learning for the\n' +
            '                    newest technique online to make sure I am always perpare for the next challenge</p>\n' +
            '\n' +
            '                <p>Other than that, I love playing basketball with my friend and cooking different style of food.\n' +
            '                    And I love to share what is nice to me to others since I believe that it would make a better\n' +
            '                    relationship.</p>'
    },
}
