import React from "react";

export function TerminalHeader(props: any)  {
    
    return (
        <div className='header-bar'>
            <div className='header-bar-button-group'>
                <button className='header-bar-button' style={{background: '#ff4848'}} onClick={() => props.setClose(true)}></button>
                <button className='header-bar-button' style={{background: '#ffda09'}}></button>
                <button className='header-bar-button' style={{background: '#57ff00'}}></button>
            </div>
            <div className='header-title mx-auto'>
                <span>{props.title} | {props.subtitle}</span>
            </div>
        </div>
    )
}
