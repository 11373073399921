const newLocal = `If You know How My Computer Works?\nStart select one of the Right button!`;
export default {
    title: 'ZEHENG LIN',
    subtitle: 'Welcome to my CMD!',
    context: {
        title: 'Zeheng Lin',
        subtitle: 'Welcome to Personal Computer!',
        content: newLocal
    },
};
