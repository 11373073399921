import React from 'react';
import PageTemplate from '../Components/PageTemplate';

function SkillPage(props: any) {
    return (
        <PageTemplate />
    )
}

export default SkillPage;
