import React from "react";
import '../style/Menu.scss';

export function Menu(props: any) {

    console.log(props)
    const handleRedirect = () => {
        window.location.href = props.path
    }

    return (
        <div className='menu-context' onClick={handleRedirect}>
            <div className='menu-img'>
                <img src={props.tag} alt={props.name}/>
            </div>
            <div className='menu-name'>
                <span>{props.name}</span>
            </div>
        </div>
    )
}
